import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col } from 'react-bootstrap';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';

const WWGuidesIntroPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page wuthering'} game="ww">
      <ul className="breadcrumb">
        <li>
          <Link to="/wuthering-waves/">Wuthering Waves</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/wuthering-waves/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Introduction to the game</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/ww/categories/category_intro.jpg"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Introduction to the game</h1>
          <h2>An introduction to Wuthering Waves.</h2>
          <p>
            Last updated: <strong>05/05/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="About the game" />
        <p>
          Wuthering Waves is a game that features open world exploration with
          rich and diverse narrative and a high degree of freedom of combat.
          Players act as Rovers, who awake after a long period of slumber,
          embark on adventures in the whole wide world made up of new era, new
          environment and new technology.
        </p>
        <p>
          Wuthering Waves is developed by Kuro Game, the creators of Punishing
          Gray Raven. The game features the best action RPG combat in the mobile
          genre and now it will be translated to a open world in the style of
          Genshin Impact.
        </p>
        <div className="ww-features">
          <div className="feature feature-1">
            <h5>Desolated by the Lament, civilization is born anew</h5>
            <p>Delve into an expansive world</p>
          </div>
          <div className="feature feature-2">
            <h5>Strike fast and put your skills to the test</h5>
            <p>Engage in smooth & fast-paced combat</p>
          </div>
          <div className="feature feature-3">
            <h5>Forte awakened, journey alongside your companions</h5>
            <p>Encounter Resonators</p>
          </div>
          <div className="feature feature-4">
            <h5>The power of your foes at your command</h5>
            <p>Collect Echoes to aid you in battle</p>
          </div>
        </div>
        <p>Check our beginner guide to learn more about the game:</p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Beginner guide"
            link="/wuthering-waves/guides/beginner-guide"
            image={
              <StaticImage
                src="../../../images/ww/categories/category_beginner.jpg"
                alt="Beginner guide"
              />
            }
          />
        </Row>
        <SectionHeader title="Gameplay" />
        <Row className="video">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="bBpitFO2UO8" />
          </Col>
        </Row>
        <SectionHeader title="Official links" />
        <ul>
          <li>
            Official Website:{' '}
            <a
              href="https://wutheringwaves.kurogame.com/en"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon width="18" icon={faArrowUpRightFromSquare} />{' '}
              Open in new window
            </a>
          </li>
          <li>
            Official YouTube:{' '}
            <a
              href="https://www.youtube.com/@WutheringWaves"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon width="18" icon={faArrowUpRightFromSquare} />{' '}
              Open in new window
            </a>
          </li>
          <li>
            Official Discord:{' '}
            <a
              href="https://discord.com/invite/wutheringwaves"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon width="18" icon={faArrowUpRightFromSquare} />{' '}
              Open in new window
            </a>
          </li>
          <li>
            Official Facebook:{' '}
            <a
              href="https://www.facebook.com/WutheringWaves.Official"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon width="18" icon={faArrowUpRightFromSquare} />{' '}
              Open in new window
            </a>
          </li>
          <li>
            Official Twitter:{' '}
            <a
              href="https://twitter.com/Wuthering_Waves"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon width="18" icon={faArrowUpRightFromSquare} />{' '}
              Open in new window
            </a>
          </li>
          <li>
            Google Play Store:{' '}
            <a
              href="https://play.google.com/store/apps/details?id=com.kurogame.wutheringwaves.global"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon width="18" icon={faArrowUpRightFromSquare} />{' '}
              Open in new window
            </a>
          </li>
          <li>
            Apple Store:{' '}
            <a
              href="https://apps.apple.com/us/app/wuthering-waves/id6475033368"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon width="18" icon={faArrowUpRightFromSquare} />{' '}
              Open in new window
            </a>
          </li>
        </ul>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default WWGuidesIntroPage;

export const Head: React.FC = () => (
  <Seo
    title="Introduction to the game | Wuthering Waves | Prydwen Institute"
    description="An introduction to Wuthering Waves."
    game="afk"
  />
);
